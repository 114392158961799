import React, { useState, useEffect } from 'react';
import HeaderMain from '../components/HeaderMain';
import * as language from '../constants/languages';
import { getCategoryProducts, getSearchProducts } from '../services/services';
import { getCategory } from '../services/services';
import CategoryGrid from '../components/CategoryGrid';
import { useLocation } from 'react-router-dom';
import SpinnerLoader from '../components/SpinnerLoader';
import ProductListingComponent from '../components/ProductListingComponent';
import ArrowLeft from '../assets/svg/keyboardright.svg'
import ArrowRight from '../assets/svg/keyboardleft.svg'
function SubCategoryPage() {

    const branchId = localStorage.getItem('storeId');
    const [productData, setproductData] = useState([])
    const [isLoading, setisLoading] = useState(true)
    const [SubCategory, setSubCategory] = useState([])
    const [count, setcount] = useState()
    const [page, setpage] = useState(1)
    const [hasNext, sethasNext] = useState(true)
    const [hasPrevious, sethasPrevious] = useState(false)
    const location = useLocation()
    useEffect(() => {
        if (location.state.params.identity === 'subcaterogries') {
            loadProductsData(location.state.params);
            getFromSubcategoryData(location.state.params);

        } else {
            getCategoryData(location.state.params);

            loadProductsData(location.state.params);
        }
    }, [location.state.params.identity])

    async function loadProductsData(params) {
        getCategoryProductData(params.id);

    }
    async function getCategoryProductData(category_id) {
        const token = localStorage.getItem('access_token');
        await getSearchProducts(language.companyidValue, "", category_id, "", "", "", "", branchId, token, page).then((categoryProducts) => {
            if (categoryProducts.status === "success") {
                // let productdata = []
                // categoryProducts.data.map((item) => {
                //     if (category_id === item.categoryId) {
                //         productdata.push(item)
                //     }
                // });
                sethasPrevious(categoryProducts.hasPrevious)
                sethasNext(categoryProducts.hasNext)
                setproductData(categoryProducts.data)
                setisLoading(false)

            } else {

                setisLoading(true)
            }
        })
    }

    function getFromSubcategoryData(params) {
        let catData = params.categorydata;
        let subcategory = []
        for (let i = 0; i < catData.subCategories?.length; i++) {
            subcategory.push(catData.subCategories[i])
        }
        setSubCategory(subcategory)
        setisLoading(false)
        setcount(subcategory.length)

    }

    async function getCategoryData(params) {
        await getCategory(language.companyidValue, branchId).then((data) => {

            if (data.status === "success") {

                let catData = data.data;
                let subcategory = []

                for (let j = 0; j < catData?.length; j++) {

                    if (params.id === catData[j].categoryId) {
                        for (let i = 0; i < catData[j].subCategories?.length; i++) {
                            subcategory.push(catData[j].subCategories[i])
                        }
                    }

                }
                setSubCategory(subcategory)
                setcount(subcategory.length)
                setisLoading(false)
            } else {

                setisLoading(false)

            }
        })
            .catch((error) => { })
    }
    async function itemAddedToCart(added, productData) {

        loadProductsData(location.state.params)

    }

    function previousPage() {
        setpage(page - 1)
    }

    function nextPage() {
        setpage(page + 1)
    }

    useEffect(() => {
        loadProductsData(location.state.params);
    }, [page])

    return (
        <div >
            <HeaderMain />
            {
                isLoading == true ?
                    <div className='spinnerContainer'>
                        <SpinnerLoader />
                    </div>
                    :

                    <div style={{ flex: 1, alignItems: 'center', marginTop: 30, paddingHorizontal: 10 }} >
                        <CategoryGrid
                            categoryData={SubCategory}
                        />
                    </div>

            }
            {
                (productData?.length == 0)
                    ? null
                    : <div style={{ marginTop: 10 }}>
                        <p style={{ fontFamily: 'Poppins-Regular', fontSize: 18, paddingLeft: 12 }}>Products in {location.state.heading}</p>

                        <ProductListingComponent
                            productData={productData}
                            addedItemToCart={(added, productData) => itemAddedToCart(added, productData)}
                        />
                        {
                            (productData.length == 0)
                                ? null
                                :
                                (hasNext || hasPrevious) &&
                                <div style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20, marginHorizontal: 10, marginTop: 40, flex: 1, display: 'flex' }}>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        {
                                            // hasPrevious ?
                                            <div onClick={() => previousPage()} style={{ visibility: hasPrevious ? 'visible' : 'hidden' }}>
                                                <img src={ArrowLeft} size={30} />
                                            </div>
                                            // : null
                                        }
                                        <div style={{ backgroundColor: '#1d4e4b', borderRadius: 5, alignItems: 'center', display: 'flex', padding: 10 }}>

                                            <p style={{ color: '#ffffff', margin: 0 }}>
                                                {page}
                                            </p>

                                        </div>
                                        {
                                            // hasNext ?
                                            <div onClick={() => nextPage()} style={{ visibility: hasNext ? 'visible' : 'hidden' }}>
                                                <img src={ArrowRight} />
                                            </div>
                                            // : null
                                        }
                                    </div>


                                </div>

                        }
                    </div>
            }
        </div>
    )
}


export default SubCategoryPage